<template>
    <v-card class="elevation-2 mt-5" id="cardborde">
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        color="verde"
      ></v-progress-linear>
      <v-card-title class="font-weight-light">
        Lista de {{Titulo}}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          color="primary"
          label="Buscar"
          hide-details
          class="m-2"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
          class="mb-2 verde primary--text"
          @click="$router.push({ name: 'mandante' })"
          >Nuevo {{Titulo}}</v-btn
        >
      </v-card-title>
      <v-tabs right>
        <v-tab @click="bEstado = true">Activos</v-tab>
        <v-tab @click="bEstado = false">Inactivos</v-tab>
      </v-tabs>
      <v-data-table
        class="mt-3"
        :headers="headers"
        :items="filteredItems"
        :no-results-text="noresult"
        no-data-text="Sin resultados que mostrar"
        :search="search"
        :custom-filter="filterOnlyCapsText"
        :footer-props="{
          'items-per-page-text': 'Resultados por página',
          'items-per-page-all-text': 'Todos',
        }"
      >
        <template #footer.page-text="props">
          {{ props.pageStart }}-{{ props.pageStop }} de {{ props.itemsLength }}
        </template>
        <template v-slot:item.url="{ item }">
          <v-icon small class="mr-2" @click="verURL(item.url)"> mdi-web </v-icon>
        </template>
        <template v-slot:item.accion="{ item }">

          <v-chip
            class="mr-2 p-1"
            color="primary"
            text-color="verde"
            small
          >
            <v-icon
            small
            @click="editItem(item._id)"
            >
            mdi-pencil
            </v-icon>
          </v-chip>

          <v-chip
            class="mr-2 p-1"
            color="primary"
            text-color="red"
            small
          >
            <v-icon
            small
            v-if="usuarioDB.data.role == 'ADMIN'"
            @click="deleteItem(item._id)"
          >
            mdi-delete
            </v-icon>
          </v-chip>

          
          
        </template>

        <template v-slot:item.activo="{ item }">
          <!--<v-chip :color="getEstados(item.estado)" dark outlined>{{ getEstadosTexto(item.estado) }}</v-chip>-->
          <v-icon :color="getEstados(item.activo)" dark outlined>
            mdi-{{ getEstadosTexto(item.activo) }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

//Definimos la clase de los elementos

export default {

  props: ['Registros','Titulo','Headers','Reactivo'],
  emits: ['saluda'],
  data() {
    return {
      loading: true,
      noresult: "No existen resultados",
      search: "",
      dialogVerURL: false,
      urlSitio: "",
      snackbar: false,
      headers: [],
      desserts: [],
      bEstado: true,
    };
  },

  computed: {
    ...mapState(["token", "notificationSystem", "usuarioDB"]),
    filteredItems() {
        if(this.desserts){
            return this.desserts.length > 0 ? this.desserts.filter((i) => {
                return i.activo === this.bEstado;
            }) : this.desserts
        }
        return this.desserts;
    },
  },
  methods: {
    editItem(id) {
      this.$router.push({
        name: "mandantess",
        params: {
          id: id,
        },
      });
      this.$emit('saluda', this.headers);
    },
    getData() {
      let config = {
        headers: {
          token: this.token,
        },
      };
      axios.get("/mandantes/mandante/", config).then((response) => {
        this.desserts = response.data;
        this.loading = false;
      });
    },
    async deleteItem(item) {
      this.loading = true;
      let config = {
        headers: {
          token: this.token,
        },
      };

      await axios
        .delete("/mandantes/mandante/" + item, config)
        .then((response) => {
          this.getData();
          this.$toast.success(
            "Eliminado con exito!",
            "",
            this.notificationSystem.options.error
          );
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.error(
            "Ocurrio un error de sistema, vuelva a intentarlo",
            "Error",
            this.notificationSystem.options.error
          );
        });
    },
    getEstados(estado) {
      if (estado == true) return "green";
      else if (estado == false) return "red";
      else return "grey";
    },

    getEstadosTexto(estado) {
      if (estado == true) return "check";
      else if (estado == false) return "close";
      else return "N/A";
    },

    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
      );
    },
  },
  created() {
    //this.getData();
    this.desserts = [];
    this.desserts = this.Registros;
  },
  watch: {
    /*Registros(){
        this.desserts = this.Registros;
        this.loading = false;
    },
    Headers(){
        this.headers = this.Headers;
        //this.loading = false;
    },*/
    Reactivo(){
        this.headers = this.Reactivo.headers;
        this.desserts = this.Reactivo.estado;
        this.loading = false;
    }
  }
};
</script>
<style>
th span {
  color: "#FF0000" !important;
  border: 0px solid #7181b8;
  border-radius: 44px;
  padding: 5px;
  background: #e8eaf6;
}
#cardborde {
  border-top: 5px solid #e8eaf6 !important;
}
</style>
