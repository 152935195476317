<template>
    <v-card class="elevation-2 mt-5" id="cardborde">
      <v-progress-linear :active="loading" :indeterminate="loading" absolute top color="verde"/>
      <v-card-title class="font-weight-light">
        Lista de Obras/Proyectos
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" color="primary" label="Buscar" hide-details class="m-2" />
        <v-spacer></v-spacer>
        <v-btn class="mb-2 verde primary--text" 
          @click="$router.push({ name: 'mandantehijo', params: { padre: padre }, })">
            Nueva Obra/Proyecto
        </v-btn>
      </v-card-title>
      <v-tabs right>
        <v-tab @click="bEstado = true">Activos</v-tab>
        <v-tab @click="bEstado = false">Inactivos</v-tab>
      </v-tabs>
      <v-data-table
        class="mt-3"
        :headers="headers"
        :items="filteredItems"
        :no-results-text="noresult"
        no-data-text="Sin resultados que mostrar"
        :search="search"
        :custom-filter="filterOnlyCapsText"
        :footer-props="{
          'items-per-page-text': 'Resultados por página',
          'items-per-page-all-text': 'Todos',
        }"
      >
        <template #footer.page-text="props">
          {{ props.pageStart }}-{{ props.pageStop }} de {{ props.itemsLength }}
        </template>
        <template v-slot:item.url="{ item }">
          <v-icon small class="mr-2" @click="verURL(item.url)"> mdi-web </v-icon>
        </template>
        <template v-slot:item.accion="{ item }">
          <v-chip class="mr-2 p-1" color="primary" text-color="verde" small>
            <v-icon small @click="editItem(item._id, item.id_padre)">mdi-pencil</v-icon>
          </v-chip>
          <v-chip class="mr-2 p-1" color="primary" text-color="red" small>
            <v-icon small v-if="usuarioDB.data.role == 'ADMIN'" @click="deleteItem(item._id)">mdi-delete</v-icon>
          </v-chip>
        </template>

        <template v-slot:item.activo="{ item }">
          <v-icon :color="getEstados(item.activo)" dark outlined>
            mdi-{{ getEstadosTexto(item.activo) }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";

//Definimos la clase de los elementos

export default {
  props: ['padre'],
  data() {
    return {
      loading: true,
      noresult: "No existen resultados",
      search: "",
      dialogVerURL: false,
      urlSitio: "",
      snackbar: false,
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "nombre",
          width: "20%",
        },
        {
          text: "Razón Social",
          value: "razon_social",
        },
        {
          text: "Rut",
          value: "rut",
          width: "15%",
        },
        {
          text: "Direccion",
          value: "direccion",
          width: "20%",
        },
        {
          text: "Estado",
          value: "activo",
        },

        {
          text: "Accion",
          value: "accion",
          sortable: "false",
        },
      ],
      desserts: [],
      bEstado: true,
      //padre: this.padre
    };
  },

  computed: {
    ...mapState(["token", "notificationSystem", "usuarioDB"]),
    filteredItems() {
      return this.desserts.filter((i) => {
        return i.activo === this.bEstado;
      });

      return this.desserts;
    },
  },
  methods: {
    editItem(id, padre) {
      this.$router.push({
        name: "mandantehijo",
        params: {
          id: id,
          padre: padre
        },
      });
    },
    getData() {
      let config = {
        headers: {
          token: this.token,
        },
      };
      if (this.padre) {
      axios.get("/mandantes/mandanteshijo/2/" +this.padre, config).then((response) => {
        this.desserts = response.data;
        this.loading = false;
      });
      }else{
        this.loading = false;
      }
    },
    async deleteItem(item) {
      this.loading = true;
      let config = {
        headers: {
          token: this.token,
        },
      };

      await axios
        .delete("/mandantes/mandante/" + item, config)
        .then((response) => {
          this.getData();
          this.$toast.success(
            "Eliminado con exito!",
            "",
            this.notificationSystem.options.error
          );
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.error(
            "Ocurrio un error de sistema, vuelva a intentarlo",
            "Error",
            this.notificationSystem.options.error
          );
        });
    },
    getEstados(estado) {
      if (estado == true) return "green";
      else if (estado == false) return "red";
      else return "grey";
    },

    getEstadosTexto(estado) {
      if (estado == true) return "check";
      else if (estado == false) return "close";
      else return "N/A";
    },

    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) !== -1
      );
    },
  },
  created() {
    this.getData();
    console.log(this.padre);
  },
};
</script>
<style>
th span {
  color: "#FF0000" !important;
  border: 0px solid #7181b8;
  border-radius: 44px;
  padding: 5px;
  background: #e8eaf6;
}
#cardborde {
  border-top: 5px solid #e8eaf6 !important;
}
</style>